<script>
  import FooterIcon from "../icons/FooterIcon.svelte";
  import ErrorIcon from "../icons/ErrorIcon.svelte";

  export let isErrorModalVisible;
  export let onCloseModal;
  export let error;
</script>

<div>
  <div class="modal {isErrorModalVisible ? 'modal--visible' : ''}">
    <div class="modal__content">
      <button on:click={onCloseModal} class="modal__close-icon">X</button>
      {#if isErrorModalVisible}
        <div class="modal__body">
          <div class="modal__icon">
            <ErrorIcon />
          </div>
          <div class="modal__title modal__title--error">Error</div>
          <div class="modal__message">
            {error}
          </div>
          <button on:click={onCloseModal} class="modal__btn">Close</button>
        </div>
      {/if}
      <div class="modal__footer">
        <span>Powered by</span>
        <div class="footer-svg">
          <FooterIcon />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../styles/modal.styles.scss";
</style>
