<svg
  width="66"
  height="19"
  viewBox="0 0 66 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,87.843138%,10.196079%);fill-opacity:1;"
    d="M 63.78125 0 L 4.851562 0 C 4.347656 0 3.863281 0.171875 3.46875 0.488281 C 3.078125 0.800781 2.800781 1.242188 2.691406 1.738281 L 0.0546875 13.261719 C -0.0195312 13.589844 -0.0195312 13.929688 0.0546875 14.257812 C 0.128906 14.585938 0.277344 14.894531 0.484375 15.15625 C 0.691406 15.417969 0.953125 15.632812 1.253906 15.777344 C 1.554688 15.921875 1.882812 16 2.214844 16 L 61.152344 16 C 61.65625 16 62.144531 15.828125 62.535156 15.511719 C 62.929688 15.195312 63.203125 14.757812 63.316406 14.261719 L 65.945312 2.742188 C 66.019531 2.414062 66.019531 2.074219 65.945312 1.746094 C 65.871094 1.414062 65.726562 1.109375 65.515625 0.84375 C 65.308594 0.582031 65.046875 0.367188 64.746094 0.222656 C 64.445312 0.0742188 64.117188 0 63.78125 0 Z M 63.78125 0 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 6.078125 2.863281 L 10.445312 7.03125 C 10.566406 7.148438 10.660156 7.292969 10.71875 7.449219 C 10.777344 7.605469 10.800781 7.777344 10.785156 7.945312 C 10.769531 8.113281 10.71875 8.277344 10.632812 8.421875 C 10.546875 8.566406 10.425781 8.6875 10.289062 8.78125 L 4.03125 12.949219 C 3.84375 13.074219 3.628906 13.136719 3.40625 13.132812 C 3.183594 13.132812 2.96875 13.0625 2.789062 12.933594 C 2.605469 12.808594 2.46875 12.628906 2.386719 12.417969 C 2.308594 12.210938 2.289062 11.984375 2.339844 11.765625 L 4.246094 3.425781 C 4.289062 3.234375 4.382812 3.058594 4.515625 2.917969 C 4.648438 2.773438 4.816406 2.667969 5 2.613281 C 5.1875 2.554688 5.382812 2.546875 5.574219 2.589844 C 5.761719 2.636719 5.9375 2.730469 6.078125 2.863281 Z M 6.078125 2.863281 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 23.902344 5.925781 C 24.433594 6.636719 24.601562 7.570312 24.40625 8.726562 L 19.460938 8.726562 C 19.480469 9.71875 19.984375 10.214844 20.964844 10.214844 C 21.292969 10.222656 21.613281 10.144531 21.898438 9.980469 C 22.183594 9.820312 22.417969 9.585938 22.582031 9.304688 L 24.085938 9.699219 C 23.8125 10.316406 23.351562 10.835938 22.773438 11.179688 C 22.191406 11.507812 21.535156 11.671875 20.867188 11.65625 C 19.96875 11.65625 19.253906 11.386719 18.71875 10.847656 C 18.183594 10.3125 17.917969 9.601562 17.921875 8.71875 C 17.910156 8.203125 18.003906 7.691406 18.203125 7.214844 C 18.398438 6.738281 18.691406 6.308594 19.0625 5.953125 C 19.40625 5.601562 19.8125 5.320312 20.265625 5.128906 C 20.714844 4.9375 21.199219 4.839844 21.691406 4.839844 C 22.117188 4.824219 22.542969 4.914062 22.929688 5.105469 C 23.316406 5.292969 23.652344 5.578125 23.902344 5.925781 Z M 22.980469 7.527344 C 22.871094 6.695312 22.398438 6.28125 21.566406 6.28125 C 21.164062 6.273438 20.773438 6.390625 20.445312 6.621094 C 20.121094 6.851562 19.863281 7.164062 19.707031 7.527344 Z M 22.980469 7.527344 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 24.523438 11.65625 L 26.738281 1.925781 L 28.460938 1.925781 L 26.25 11.65625 Z M 24.523438 11.65625 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 33.824219 5.914062 C 34.355469 6.625 34.523438 7.558594 34.328125 8.714844 L 29.386719 8.714844 C 29.402344 9.707031 29.90625 10.207031 30.890625 10.207031 C 31.214844 10.210938 31.539062 10.128906 31.824219 9.96875 C 32.105469 9.804688 32.34375 9.570312 32.507812 9.285156 L 34.003906 9.679688 C 33.730469 10.296875 33.269531 10.816406 32.691406 11.160156 C 32.109375 11.492188 31.453125 11.664062 30.785156 11.65625 C 29.886719 11.65625 29.167969 11.386719 28.632812 10.851562 C 28.101562 10.316406 27.835938 9.605469 27.839844 8.71875 C 27.839844 7.703125 28.234375 6.730469 28.9375 6.007812 C 29.640625 5.285156 30.597656 4.871094 31.601562 4.851562 C 32.03125 4.835938 32.457031 4.921875 32.84375 5.105469 C 33.230469 5.292969 33.566406 5.570312 33.824219 5.914062 Z M 32.902344 7.515625 C 32.792969 6.683594 32.320312 6.269531 31.488281 6.269531 C 31.089844 6.261719 30.695312 6.378906 30.367188 6.609375 C 30.042969 6.835938 29.789062 7.152344 29.628906 7.515625 Z M 32.902344 7.515625 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 41.632812 5.796875 C 42.152344 6.445312 42.335938 7.257812 42.183594 8.234375 C 42.058594 9.175781 41.609375 10.039062 40.921875 10.683594 C 40.277344 11.300781 39.421875 11.640625 38.535156 11.632812 C 37.515625 11.632812 36.839844 11.296875 36.433594 10.601562 L 35.652344 14.09375 L 33.914062 14.09375 L 36.03125 4.855469 L 37.761719 4.855469 L 37.609375 5.460938 C 38.1875 5.035156 38.890625 4.820312 39.601562 4.851562 C 40 4.84375 40.394531 4.925781 40.757812 5.09375 C 41.101562 5.253906 41.398438 5.496094 41.632812 5.796875 Z M 39.839844 9.625 C 40.230469 9.261719 40.480469 8.773438 40.554688 8.242188 C 40.648438 7.65625 40.558594 7.1875 40.277344 6.839844 C 40.132812 6.664062 39.949219 6.523438 39.742188 6.429688 C 39.535156 6.339844 39.308594 6.296875 39.082031 6.3125 C 38.582031 6.320312 38.105469 6.511719 37.738281 6.851562 C 37.324219 7.203125 37.050781 7.691406 36.96875 8.234375 C 36.910156 8.472656 36.910156 8.722656 36.96875 8.964844 C 37.023438 9.203125 37.136719 9.425781 37.292969 9.617188 C 37.4375 9.785156 37.617188 9.921875 37.824219 10.011719 C 38.023438 10.105469 38.246094 10.148438 38.46875 10.144531 C 38.976562 10.152344 39.46875 9.964844 39.839844 9.617188 Z M 39.839844 9.625 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 49.203125 11.65625 L 47.480469 11.65625 L 47.65625 10.984375 C 47.070312 11.429688 46.359375 11.664062 45.628906 11.65625 C 45.234375 11.65625 44.847656 11.5625 44.496094 11.382812 C 44.148438 11.207031 43.839844 10.957031 43.59375 10.652344 C 43.070312 10.003906 42.886719 9.191406 43.039062 8.214844 C 43.171875 7.273438 43.621094 6.410156 44.316406 5.769531 C 44.960938 5.15625 45.816406 4.816406 46.703125 4.824219 C 47.722656 4.824219 48.429688 5.164062 48.828125 5.839844 L 49.042969 4.851562 L 50.769531 4.851562 Z M 47.488281 9.605469 C 47.902344 9.253906 48.175781 8.761719 48.257812 8.222656 C 48.3125 7.980469 48.3125 7.730469 48.257812 7.492188 C 48.199219 7.253906 48.089844 7.027344 47.933594 6.839844 C 47.789062 6.667969 47.609375 6.535156 47.40625 6.445312 C 47.199219 6.351562 46.980469 6.308594 46.753906 6.3125 C 46.25 6.304688 45.757812 6.492188 45.386719 6.839844 C 44.996094 7.203125 44.742188 7.691406 44.667969 8.222656 C 44.578125 8.808594 44.671875 9.273438 44.949219 9.625 C 45.09375 9.800781 45.277344 9.941406 45.484375 10.035156 C 45.691406 10.125 45.917969 10.164062 46.144531 10.152344 C 46.640625 10.144531 47.117188 9.949219 47.488281 9.613281 Z M 47.488281 9.605469 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 52.136719 9.421875 C 52.160156 9.566406 52.214844 9.703125 52.296875 9.820312 C 52.382812 9.9375 52.488281 10.035156 52.617188 10.105469 C 52.886719 10.261719 53.195312 10.339844 53.507812 10.335938 C 53.769531 10.347656 54.027344 10.277344 54.25 10.136719 C 54.332031 10.089844 54.398438 10.023438 54.445312 9.941406 C 54.496094 9.859375 54.527344 9.765625 54.53125 9.667969 C 54.53125 9.425781 54.359375 9.257812 54.023438 9.152344 L 52.773438 8.757812 C 51.796875 8.457031 51.3125 7.863281 51.3125 6.96875 C 51.304688 6.679688 51.367188 6.390625 51.488281 6.125 C 51.609375 5.863281 51.792969 5.632812 52.019531 5.453125 C 52.488281 5.058594 53.125 4.859375 53.929688 4.859375 C 54.585938 4.835938 55.234375 5.011719 55.792969 5.359375 C 56.023438 5.5 56.226562 5.683594 56.386719 5.902344 C 56.542969 6.121094 56.65625 6.371094 56.71875 6.636719 L 55.277344 7.074219 C 55.257812 6.941406 55.210938 6.816406 55.136719 6.703125 C 55.0625 6.59375 54.964844 6.5 54.855469 6.429688 C 54.574219 6.257812 54.253906 6.175781 53.929688 6.1875 C 53.671875 6.164062 53.417969 6.230469 53.203125 6.375 C 53.136719 6.425781 53.082031 6.496094 53.042969 6.570312 C 53.003906 6.652344 52.984375 6.738281 52.984375 6.828125 C 52.984375 7.089844 53.226562 7.304688 53.703125 7.457031 L 54.484375 7.703125 C 55.613281 8.066406 56.179688 8.691406 56.179688 9.582031 C 56.183594 9.882812 56.113281 10.183594 55.972656 10.449219 C 55.832031 10.71875 55.628906 10.941406 55.375 11.109375 C 54.785156 11.496094 54.085938 11.6875 53.382812 11.65625 C 52.722656 11.667969 52.074219 11.515625 51.488281 11.203125 C 51.234375 11.078125 51.007812 10.894531 50.832031 10.667969 C 50.65625 10.441406 50.527344 10.175781 50.464844 9.898438 Z M 52.136719 9.421875 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 58.382812 9.421875 C 58.402344 9.5625 58.457031 9.699219 58.539062 9.820312 C 58.621094 9.9375 58.730469 10.035156 58.859375 10.101562 C 59.128906 10.257812 59.433594 10.339844 59.742188 10.335938 C 60.003906 10.347656 60.265625 10.277344 60.488281 10.136719 C 60.574219 10.089844 60.644531 10.023438 60.691406 9.941406 C 60.742188 9.859375 60.773438 9.765625 60.777344 9.667969 C 60.777344 9.425781 60.605469 9.253906 60.265625 9.152344 L 59.015625 8.757812 C 58.039062 8.457031 57.550781 7.863281 57.550781 6.980469 C 57.546875 6.6875 57.609375 6.398438 57.730469 6.136719 C 57.851562 5.871094 58.03125 5.640625 58.257812 5.460938 C 58.730469 5.066406 59.367188 4.867188 60.164062 4.867188 C 60.820312 4.84375 61.472656 5.019531 62.03125 5.371094 C 62.261719 5.507812 62.460938 5.695312 62.621094 5.914062 C 62.78125 6.132812 62.894531 6.378906 62.953125 6.644531 L 61.523438 7.066406 C 61.503906 6.933594 61.453125 6.808594 61.382812 6.695312 C 61.308594 6.585938 61.210938 6.492188 61.097656 6.421875 C 60.816406 6.246094 60.488281 6.164062 60.15625 6.179688 C 59.898438 6.160156 59.640625 6.230469 59.429688 6.378906 C 59.363281 6.433594 59.308594 6.503906 59.269531 6.582031 C 59.230469 6.660156 59.214844 6.746094 59.214844 6.835938 C 59.214844 7.097656 59.453125 7.3125 59.929688 7.464844 L 60.710938 7.707031 C 61.84375 8.066406 62.40625 8.695312 62.40625 9.585938 C 62.414062 9.886719 62.347656 10.183594 62.210938 10.449219 C 62.074219 10.71875 61.871094 10.945312 61.625 11.113281 C 61.035156 11.5 60.335938 11.6875 59.632812 11.65625 C 58.976562 11.667969 58.324219 11.515625 57.742188 11.203125 C 57.488281 11.074219 57.261719 10.890625 57.085938 10.664062 C 56.90625 10.4375 56.78125 10.171875 56.714844 9.890625 Z M 58.382812 9.421875 "
  />
  <path
    style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,34.117648%,71.764708%);fill-opacity:1;"
    d="M 17.351562 4.429688 L 15.710938 11.65625 L 13.980469 11.65625 L 15.628906 4.421875 L 12.792969 4.421875 L 13.148438 2.839844 L 20.53125 2.839844 L 20.175781 4.421875 Z M 17.351562 4.429688 "
  />
</svg>
