<script>
  export let width,
    height,
    viewBox,
    isSmall = false;
</script>

<svg fill="none" {height} {viewBox} {width} xmlns="http://www.w3.org/2000/svg">
  {#if isSmall}
    <!-- the icon follow the colors of the text and is smaller -->
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M193.281 4.73094e-09H14.6988C13.1791 -5.69958e-05 11.7043 0.514964 10.515 1.46101C9.3257 2.40706 8.49218 3.72827 8.15045 5.20902L0.168032 39.7876C-0.0560974 40.771 -0.0560095 41.7922 0.168289 42.7755C0.392587 43.7589 0.835343 44.6791 1.46373 45.468C2.09212 46.2569 2.89002 46.8942 3.79827 47.3327C4.70653 47.7713 5.70184 47.9997 6.71042 48.0012H185.316C186.837 48.0009 188.312 47.4851 189.502 46.5379C190.691 45.5906 191.524 44.2681 191.865 42.7862L199.829 8.22539C200.056 7.24073 200.057 6.21771 199.833 5.23245C199.61 4.24718 199.167 3.32503 198.537 2.5346C197.908 1.74416 197.109 1.10579 196.199 0.666964C195.289 0.228142 194.291 0.000167292 193.281 4.73094e-09V4.73094e-09Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M18.4206 8.58683L31.6535 21.0968C32.0211 21.4457 32.3049 21.8734 32.4835 22.3477C32.6621 22.822 32.7309 23.3307 32.6847 23.8354C32.6385 24.3401 32.4785 24.8279 32.2168 25.2619C31.955 25.6959 31.5983 26.065 31.1735 26.3413L12.21 38.8513C11.6498 39.2191 10.9924 39.411 10.3222 39.4023C9.65207 39.3935 8.99984 39.1846 8.44936 38.8023C7.89888 38.4201 7.4753 37.8819 7.23305 37.257C6.9908 36.6321 6.94095 35.9491 7.08991 35.2956L12.8619 10.2817C12.9961 9.70884 13.2785 9.18127 13.6806 8.75178C14.0827 8.32228 14.5906 8.00591 15.1534 7.83431C15.7162 7.66271 16.3142 7.6419 16.8875 7.77397C17.4609 7.90603 17.9895 8.18633 18.4206 8.58683Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M72.4363 17.7783C74.0403 19.9077 74.548 22.7068 73.9593 26.1755H58.9782C59.0375 29.1583 60.5565 30.6497 63.5354 30.6497C64.5219 30.6705 65.4962 30.4278 66.3577 29.9465C67.2192 29.4653 67.9367 28.7629 68.4362 27.9118L72.9934 29.097C72.1588 30.9541 70.767 32.5053 69.011 33.5357C67.2498 34.5203 65.2563 35.0136 63.2391 34.9639C60.5131 34.9639 58.3402 34.1579 56.7204 32.546C55.1006 30.9341 54.2966 28.8047 54.3085 26.1577C54.2737 24.6103 54.5631 23.0728 55.1582 21.6439C55.7532 20.2151 56.6406 18.9266 57.7634 17.8612C58.802 16.8025 60.0414 15.9616 61.409 15.3877C62.7766 14.8138 64.2449 14.5185 65.728 14.5189C67.0273 14.4734 68.3179 14.7467 69.4872 15.3148C70.6566 15.883 71.6691 16.7287 72.4363 17.7783ZM69.6333 22.5843C69.3014 20.0874 67.8732 18.839 65.3487 18.839C64.1375 18.8171 62.9498 19.1757 61.9531 19.8642C60.9721 20.5494 60.1974 21.4901 59.713 22.5843H69.6333Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M74.3096 34.9639L81.0297 5.77795H86.2506L79.5482 34.9639H74.3096Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M102.499 17.7426C104.111 19.872 104.621 22.6711 104.028 26.1399H89.047C89.1023 29.1266 90.6214 30.62 93.6042 30.62C94.5937 30.6378 95.5699 30.3906 96.4317 29.904C97.2934 29.4174 98.0093 28.709 98.505 27.8525L103.044 29.0377C102.208 30.8936 100.817 32.4445 99.0621 33.4763C97.3066 34.4817 95.3128 34.9955 93.2901 34.9638C90.5641 34.9638 88.3912 34.1598 86.7714 32.5519C85.1516 30.9439 84.3477 28.8125 84.3595 26.1576C84.3606 23.1149 85.5569 20.1943 87.6907 18.0251C89.8244 15.8559 92.7249 14.6116 95.7672 14.5603C97.0631 14.5062 98.3527 14.7676 99.5254 15.3219C100.698 15.8762 101.718 16.7069 102.499 17.7426ZM99.7021 22.5487C99.3702 20.0558 97.9421 18.8073 95.4176 18.8034C94.2063 18.7814 93.0187 19.14 92.0219 19.8286C91.0397 20.5124 90.2647 21.4535 89.7819 22.5487H99.7021Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M126.157 17.3871C127.737 19.3309 128.296 21.7704 127.834 24.7058C127.446 27.5242 126.093 30.121 124.006 32.0542C122.052 33.9026 119.459 34.9222 116.77 34.8987C113.682 34.8987 111.638 33.8853 110.405 31.7993L108.035 42.2826H102.767L109.19 14.5663H114.429L113.973 16.3856C115.717 15.1071 117.845 14.4631 120.006 14.5604C121.213 14.5313 122.411 14.7786 123.508 15.2834C124.546 15.7633 125.454 16.4847 126.157 17.3871ZM120.729 28.8719C121.908 27.7805 122.674 26.3151 122.897 24.7236C123.182 22.9695 122.899 21.567 122.05 20.5161C121.612 19.9874 121.057 19.5688 120.428 19.294C119.799 19.0192 119.114 18.8958 118.429 18.9338C116.92 18.9569 115.471 19.5325 114.358 20.5516C113.101 21.6046 112.271 23.0793 112.023 24.6999C111.852 25.4201 111.852 26.1705 112.022 26.8908C112.193 27.6112 112.53 28.2815 113.007 28.8482C113.447 29.3576 113.996 29.7625 114.613 30.0334C115.228 30.3121 115.899 30.4479 116.574 30.4304C118.11 30.4605 119.597 29.8932 120.723 28.8482L120.729 28.8719Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M149.102 34.9638H143.876L144.409 32.9549C142.643 34.2887 140.483 34.9956 138.27 34.9638C137.076 34.9651 135.898 34.6849 134.833 34.146C133.778 33.6153 132.847 32.8682 132.101 31.9534C130.52 30.0096 129.961 27.572 130.424 24.6406C130.826 21.8241 132.191 19.2339 134.287 17.31C136.242 15.4653 138.835 14.4482 141.523 14.4715C144.609 14.4715 146.756 15.4888 147.965 17.5234L148.611 14.5604H153.843L149.102 34.9638ZM143.905 28.8126C145.162 27.7596 145.992 26.2849 146.24 24.6643C146.405 23.9437 146.403 23.1948 146.232 22.4754C146.062 21.7561 145.728 21.0857 145.256 20.5161C144.814 20.0082 144.266 19.6036 143.651 19.3308C143.031 19.0576 142.36 18.9222 141.683 18.9338C140.147 18.9091 138.661 19.478 137.535 20.522C136.353 21.6121 135.584 23.0778 135.36 24.6702C135.083 26.4204 135.368 27.8229 136.213 28.8777C136.65 29.4063 137.204 29.8249 137.832 30.0997C138.46 30.3746 139.144 30.498 139.828 30.46C141.337 30.4299 142.784 29.8529 143.899 28.8363L143.905 28.8126Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M157.992 28.2673C158.06 28.6968 158.227 29.1048 158.478 29.4594C158.73 29.814 159.061 30.1057 159.444 30.3118C160.263 30.7819 161.195 31.0215 162.14 31.0052C162.936 31.0444 163.724 30.8374 164.398 30.4126C164.643 30.2696 164.847 30.0654 164.99 29.82C165.142 29.575 165.23 29.2958 165.245 29.0081C165.245 28.2792 164.73 27.7695 163.704 27.4614L159.918 26.2762C156.966 25.3754 155.491 23.5857 155.491 20.9071C155.471 20.0348 155.654 19.1697 156.025 18.38C156.397 17.5903 156.946 16.8974 157.63 16.3559C159.06 15.1707 160.99 14.5781 163.42 14.5781C165.412 14.5096 167.379 15.0334 169.073 16.0833C169.773 16.5003 170.381 17.0535 170.863 17.7101C171.344 18.3667 171.689 19.1133 171.876 19.9056L167.509 21.2212C167.45 20.8241 167.302 20.4454 167.076 20.1131C166.855 19.7799 166.564 19.4989 166.223 19.2893C165.381 18.7753 164.405 18.5236 163.42 18.5664C162.645 18.495 161.869 18.6917 161.221 19.1234C161.017 19.2823 160.851 19.4847 160.735 19.716C160.622 19.9549 160.563 20.216 160.564 20.4805C160.564 21.2746 161.287 21.9087 162.738 22.3709L165.103 23.1116C168.528 24.1941 170.241 26.0727 170.241 28.7473C170.257 29.6541 170.042 30.55 169.616 31.3505C169.19 32.1511 168.567 32.8297 167.805 33.3223C166.011 34.4862 163.897 35.0602 161.761 34.9638C159.767 35.007 157.795 34.542 156.03 33.6126C155.255 33.2301 154.575 32.6794 154.039 32.0008C153.501 31.3215 153.119 30.5326 152.919 29.6896L157.992 28.2673Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M176.913 28.2614C176.977 28.6918 177.141 29.1013 177.392 29.4565C177.643 29.8118 177.974 30.1028 178.359 30.3059C179.174 30.7739 180.099 31.0153 181.038 31.0052C181.835 31.0406 182.624 30.834 183.302 30.4125C183.554 30.27 183.766 30.0659 183.918 29.8193C184.071 29.5727 184.158 29.2916 184.173 29.0021C184.173 28.2792 183.651 27.7636 182.626 27.4554L178.839 26.2702C175.88 25.3695 174.401 23.5916 174.401 20.9368C174.384 20.0639 174.568 19.1989 174.939 18.4087C175.31 17.6184 175.858 16.9242 176.54 16.3796C177.974 15.1944 179.898 14.6018 182.312 14.6018C184.306 14.5338 186.276 15.0598 187.971 16.1129C188.671 16.5284 189.28 17.0812 189.761 17.7381C190.241 18.395 190.584 19.1424 190.768 19.9353L186.436 21.1975C186.371 20.8021 186.223 20.4247 186.004 20.0893C185.779 19.7573 185.486 19.4767 185.145 19.2656C184.288 18.7412 183.292 18.4891 182.288 18.5426C181.509 18.4817 180.733 18.691 180.09 19.1352C179.884 19.2984 179.718 19.5066 179.605 19.7439C179.492 19.9812 179.435 20.2413 179.438 20.5042C179.438 21.2983 180.161 21.9323 181.607 22.3946L183.977 23.1235C187.402 24.202 189.115 26.0806 189.115 28.7592C189.139 29.6599 188.933 30.5519 188.519 31.3519C188.104 32.1519 187.494 32.8338 186.745 33.3341C184.951 34.4945 182.84 35.0644 180.706 34.9638C178.712 35.0075 176.74 34.5425 174.975 33.6126C174.201 33.2242 173.521 32.6705 172.983 31.9906C172.445 31.3107 172.064 30.5212 171.864 29.6777L176.913 28.2614Z"
    />
    <path
      style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1;"
      d="M52.5842 13.2922L47.6064 34.9639H42.3618L47.3634 13.2626H38.7646L39.8491 8.52173H62.22L61.1415 13.2626L52.5842 13.2922Z"
    />
  {:else}
    <!-- the icon have the original color and is bigger -->
    <path
      d="M193.281 4.73094e-09H14.6988C13.1791 -5.69958e-05 11.7043 0.514964 10.515 1.46101C9.3257 2.40706 8.49218 3.72827 8.15045 5.20902L0.168032 39.7876C-0.0560974 40.771 -0.0560095 41.7922 0.168289 42.7755C0.392587 43.7589 0.835343 44.6791 1.46373 45.468C2.09212 46.2569 2.89002 46.8942 3.79827 47.3327C4.70653 47.7713 5.70184 47.9997 6.71042 48.0012H185.316C186.837 48.0009 188.312 47.4851 189.502 46.5379C190.691 45.5906 191.524 44.2681 191.865 42.7862L199.829 8.22539C200.056 7.24073 200.057 6.21771 199.833 5.23245C199.61 4.24718 199.167 3.32503 198.537 2.5346C197.908 1.74416 197.109 1.10579 196.199 0.666964C195.289 0.228142 194.291 0.000167292 193.281 4.73094e-09V4.73094e-09Z"
      fill="#FEE01A"
    />
    <path
      d="M18.4206 8.58683L31.6535 21.0968C32.0211 21.4457 32.3049 21.8734 32.4835 22.3477C32.6621 22.822 32.7309 23.3307 32.6847 23.8354C32.6385 24.3401 32.4785 24.8279 32.2168 25.2619C31.955 25.6959 31.5983 26.065 31.1735 26.3413L12.21 38.8513C11.6498 39.2191 10.9924 39.411 10.3222 39.4023C9.65207 39.3935 8.99984 39.1846 8.44936 38.8023C7.89888 38.4201 7.4753 37.8819 7.23305 37.257C6.9908 36.6321 6.94095 35.9491 7.08991 35.2956L12.8619 10.2817C12.9961 9.70884 13.2785 9.18127 13.6806 8.75178C14.0827 8.32228 14.5906 8.00591 15.1534 7.83431C15.7162 7.66271 16.3142 7.6419 16.8875 7.77397C17.4609 7.90603 17.9895 8.18633 18.4206 8.58683Z"
      fill="#0057B7"
    />
    <path
      d="M72.4363 17.7783C74.0403 19.9077 74.548 22.7068 73.9593 26.1755H58.9782C59.0375 29.1583 60.5565 30.6497 63.5354 30.6497C64.5219 30.6705 65.4962 30.4278 66.3577 29.9465C67.2192 29.4653 67.9367 28.7629 68.4362 27.9118L72.9934 29.097C72.1588 30.9541 70.767 32.5053 69.011 33.5357C67.2498 34.5203 65.2563 35.0136 63.2391 34.9639C60.5131 34.9639 58.3402 34.1579 56.7204 32.546C55.1006 30.9341 54.2966 28.8047 54.3085 26.1577C54.2737 24.6103 54.5631 23.0728 55.1582 21.6439C55.7532 20.2151 56.6406 18.9266 57.7634 17.8612C58.802 16.8025 60.0414 15.9616 61.409 15.3877C62.7766 14.8138 64.2449 14.5185 65.728 14.5189C67.0273 14.4734 68.3179 14.7467 69.4872 15.3148C70.6566 15.883 71.6691 16.7287 72.4363 17.7783ZM69.6333 22.5843C69.3014 20.0874 67.8732 18.839 65.3487 18.839C64.1375 18.8171 62.9498 19.1757 61.9531 19.8642C60.9721 20.5494 60.1974 21.4901 59.713 22.5843H69.6333Z"
      fill="#0057B7"
    />
    <path
      d="M74.3096 34.9639L81.0297 5.77795H86.2506L79.5482 34.9639H74.3096Z"
      fill="#0057B7"
    />
    <path
      d="M102.499 17.7426C104.111 19.872 104.621 22.6711 104.028 26.1399H89.047C89.1023 29.1266 90.6214 30.62 93.6042 30.62C94.5937 30.6378 95.5699 30.3906 96.4317 29.904C97.2934 29.4174 98.0093 28.709 98.505 27.8525L103.044 29.0377C102.208 30.8936 100.817 32.4445 99.0621 33.4763C97.3066 34.4817 95.3128 34.9955 93.2901 34.9638C90.5641 34.9638 88.3912 34.1598 86.7714 32.5519C85.1516 30.9439 84.3477 28.8125 84.3595 26.1576C84.3606 23.1149 85.5569 20.1943 87.6907 18.0251C89.8244 15.8559 92.7249 14.6116 95.7672 14.5603C97.0631 14.5062 98.3527 14.7676 99.5254 15.3219C100.698 15.8762 101.718 16.7069 102.499 17.7426ZM99.7021 22.5487C99.3702 20.0558 97.9421 18.8073 95.4176 18.8034C94.2063 18.7814 93.0187 19.14 92.0219 19.8286C91.0397 20.5124 90.2647 21.4535 89.7819 22.5487H99.7021Z"
      fill="#0057B7"
    />
    <path
      d="M126.157 17.3871C127.737 19.3309 128.296 21.7704 127.834 24.7058C127.446 27.5242 126.093 30.121 124.006 32.0542C122.052 33.9026 119.459 34.9222 116.77 34.8987C113.682 34.8987 111.638 33.8853 110.405 31.7993L108.035 42.2826H102.767L109.19 14.5663H114.429L113.973 16.3856C115.717 15.1071 117.845 14.4631 120.006 14.5604C121.213 14.5313 122.411 14.7786 123.508 15.2834C124.546 15.7633 125.454 16.4847 126.157 17.3871ZM120.729 28.8719C121.908 27.7805 122.674 26.3151 122.897 24.7236C123.182 22.9695 122.899 21.567 122.05 20.5161C121.612 19.9874 121.057 19.5688 120.428 19.294C119.799 19.0192 119.114 18.8958 118.429 18.9338C116.92 18.9569 115.471 19.5325 114.358 20.5516C113.101 21.6046 112.271 23.0793 112.023 24.6999C111.852 25.4201 111.852 26.1705 112.022 26.8908C112.193 27.6112 112.53 28.2815 113.007 28.8482C113.447 29.3576 113.996 29.7625 114.613 30.0334C115.228 30.3121 115.899 30.4479 116.574 30.4304C118.11 30.4605 119.597 29.8932 120.723 28.8482L120.729 28.8719Z"
      fill="#0057B7"
    />
    <path
      d="M149.102 34.9638H143.876L144.409 32.9549C142.643 34.2887 140.483 34.9956 138.27 34.9638C137.076 34.9651 135.898 34.6849 134.833 34.146C133.778 33.6153 132.847 32.8682 132.101 31.9534C130.52 30.0096 129.961 27.572 130.424 24.6406C130.826 21.8241 132.191 19.2339 134.287 17.31C136.242 15.4653 138.835 14.4482 141.523 14.4715C144.609 14.4715 146.756 15.4888 147.965 17.5234L148.611 14.5604H153.843L149.102 34.9638ZM143.905 28.8126C145.162 27.7596 145.992 26.2849 146.24 24.6643C146.405 23.9437 146.403 23.1948 146.232 22.4754C146.062 21.7561 145.728 21.0857 145.256 20.5161C144.814 20.0082 144.266 19.6036 143.651 19.3308C143.031 19.0576 142.36 18.9222 141.683 18.9338C140.147 18.9091 138.661 19.478 137.535 20.522C136.353 21.6121 135.584 23.0778 135.36 24.6702C135.083 26.4204 135.368 27.8229 136.213 28.8777C136.65 29.4063 137.204 29.8249 137.832 30.0997C138.46 30.3746 139.144 30.498 139.828 30.46C141.337 30.4299 142.784 29.8529 143.899 28.8363L143.905 28.8126Z"
      fill="#0057B7"
    />
    <path
      d="M157.992 28.2673C158.06 28.6968 158.227 29.1048 158.478 29.4594C158.73 29.814 159.061 30.1057 159.444 30.3118C160.263 30.7819 161.195 31.0215 162.14 31.0052C162.936 31.0444 163.724 30.8374 164.398 30.4126C164.643 30.2696 164.847 30.0654 164.99 29.82C165.142 29.575 165.23 29.2958 165.245 29.0081C165.245 28.2792 164.73 27.7695 163.704 27.4614L159.918 26.2762C156.966 25.3754 155.491 23.5857 155.491 20.9071C155.471 20.0348 155.654 19.1697 156.025 18.38C156.397 17.5903 156.946 16.8974 157.63 16.3559C159.06 15.1707 160.99 14.5781 163.42 14.5781C165.412 14.5096 167.379 15.0334 169.073 16.0833C169.773 16.5003 170.381 17.0535 170.863 17.7101C171.344 18.3667 171.689 19.1133 171.876 19.9056L167.509 21.2212C167.45 20.8241 167.302 20.4454 167.076 20.1131C166.855 19.7799 166.564 19.4989 166.223 19.2893C165.381 18.7753 164.405 18.5236 163.42 18.5664C162.645 18.495 161.869 18.6917 161.221 19.1234C161.017 19.2823 160.851 19.4847 160.735 19.716C160.622 19.9549 160.563 20.216 160.564 20.4805C160.564 21.2746 161.287 21.9087 162.738 22.3709L165.103 23.1116C168.528 24.1941 170.241 26.0727 170.241 28.7473C170.257 29.6541 170.042 30.55 169.616 31.3505C169.19 32.1511 168.567 32.8297 167.805 33.3223C166.011 34.4862 163.897 35.0602 161.761 34.9638C159.767 35.007 157.795 34.542 156.03 33.6126C155.255 33.2301 154.575 32.6794 154.039 32.0008C153.501 31.3215 153.119 30.5326 152.919 29.6896L157.992 28.2673Z"
      fill="#0057B7"
    />
    <path
      d="M176.913 28.2614C176.977 28.6918 177.141 29.1013 177.392 29.4565C177.643 29.8118 177.974 30.1028 178.359 30.3059C179.174 30.7739 180.099 31.0153 181.038 31.0052C181.835 31.0406 182.624 30.834 183.302 30.4125C183.554 30.27 183.766 30.0659 183.918 29.8193C184.071 29.5727 184.158 29.2916 184.173 29.0021C184.173 28.2792 183.651 27.7636 182.626 27.4554L178.839 26.2702C175.88 25.3695 174.401 23.5916 174.401 20.9368C174.384 20.0639 174.568 19.1989 174.939 18.4087C175.31 17.6184 175.858 16.9242 176.54 16.3796C177.974 15.1944 179.898 14.6018 182.312 14.6018C184.306 14.5338 186.276 15.0598 187.971 16.1129C188.671 16.5284 189.28 17.0812 189.761 17.7381C190.241 18.395 190.584 19.1424 190.768 19.9353L186.436 21.1975C186.371 20.8021 186.223 20.4247 186.004 20.0893C185.779 19.7573 185.486 19.4767 185.145 19.2656C184.288 18.7412 183.292 18.4891 182.288 18.5426C181.509 18.4817 180.733 18.691 180.09 19.1352C179.884 19.2984 179.718 19.5066 179.605 19.7439C179.492 19.9812 179.435 20.2413 179.438 20.5042C179.438 21.2983 180.161 21.9323 181.607 22.3946L183.977 23.1235C187.402 24.202 189.115 26.0806 189.115 28.7592C189.139 29.6599 188.933 30.5519 188.519 31.3519C188.104 32.1519 187.494 32.8338 186.745 33.3341C184.951 34.4945 182.84 35.0644 180.706 34.9638C178.712 35.0075 176.74 34.5425 174.975 33.6126C174.201 33.2242 173.521 32.6705 172.983 31.9906C172.445 31.3107 172.064 30.5212 171.864 29.6777L176.913 28.2614Z"
      fill="#0057B7"
    />
    <path
      d="M52.5842 13.2922L47.6064 34.9639H42.3618L47.3634 13.2626H38.7646L39.8491 8.52173H62.22L61.1415 13.2626L52.5842 13.2922Z"
      fill="#0057B7"
    />
  {/if}
</svg>
