import { constants } from "ethers";

export const BN_ZERO = constants.Zero;

/**
 * Web3 Service
 */
export class AbstractWeb3Service {
  /**
   * Constructor
   * @param web3Provider
   * @param speedFactor 1.3 means 30% faster/more expensive, 1=normal
   */
  constructor(web3Provider, speedFactor) {
    this.web3Provider = web3Provider;
    this.speedFactor = speedFactor;
  }

  /**
   * Parse transaction to friendly format
   * @param contract
   * @param tx
   */
  async parseTx(contract, tx) {
    await tx.wait();

    const parsedResponse = contract.interface.parseTransaction(tx);

    return {
      codeName: parsedResponse.name,
      error: false,
      hash: tx.hash,
    };
  }

  /**
   * Parse tx error to friendly format
   * @param error
   * @param contractInterface
   */
  parseTxError(error, contractInterface) {
    try {
      if (!error?.error?.data?.data) {
        return {
          codeName: error.code,
          message: error?.message,
          error: true,
        };
      }

      if (!contractInterface) {
        return {
          codeName: "Unexpected_Error",
          error: true,
        };
      }

      const errorCode = error.error.data.data;

      const parsedError = contractInterface.parseError(errorCode);

      return {
        codeName: parsedError.name,
        error: true,
      };
    } catch (err) {
      return {
        codeName: "Unexpected_Error",
        error: true,
      };
    }
  }

  /**
   * Default options for stream transactions
   */
  async getTransactionDefaultOptions() {
    const gasPrice = await this.web3Provider.getGasPrice();
    return {
      gasPrice: Number.parseInt(String(gasPrice * this.speedFactor)),
    };
  }
}
