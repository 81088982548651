<script context="module">
  import { paymentErrorsStore } from "../../store.js";

  export const Providers = {
    CARD: "STRIPE",
    CRYPTO: "CRYPTO",
  };

  export const PaymentProcessingSteps = {
    WALLET_CONNECT: "Connecting to your wallet",
    SEND_AUTH: "Sending the authorization to merchant",
    CONFIRMATION:
      "Please authorise the merchant to set up a stream from your account from your wallet.\n" +
      "\n" +
      "Waiting for confirmation",
  };

  export const AuthorizationActions = {
    NEW_AUTHORIZATION_REQUIRED: "NEW_AUTHORIZATION_REQUIRED",
    UPDATE_AUTHORIZATION_REQUIRED: "UPDATE_AUTHORIZATION_REQUIRED",
    NO_UPDATE_AUTHORIZATION_REQUIRED: "NO_UPDATE_AUTHORIZATION_REQUIRED",
  };

  export const paymentStatuses = {
    CREATED: {
      name: "CREATED",
      label: "Payment session initialized",
      color: "#FFB238",
    },
    INITIATED: { name: "INITIATED", label: "Processing", color: "#FFB238" },
    CANCEL_INITIATED: {
      name: "CANCEL_INITIATED",
      label: "Stopping stream",
      color: "#FFB238",
    },
    SENT_TO_MEMPOOL: {
      name: "SENT_TO_MEMPOOL",
      label: "Setting up stream. Waiting for confirmation.",
      color: "#F3F332",
    },
    COMPLETED: {
      name: "COMPLETED",
      label: "Transaction is executed",
      color: "#A2DD5A",
    },
    FAILED: { name: "FAILED", label: "Transaction failed", color: "#DD5A5A" },
    CANCEL_FAILED: {
      name: "CANCEL_FAILED",
      label: "Error on stopping stream",
      color: "#DD5A5A",
    },
    CANCEL_CONFIRMED: {
      name: "CANCEL_CONFIRMED",
      label: "Stream stopped",
      color: "#DD5A5A",
    },
  };

  // helper that validates options object and returns true if something is invalid
  export const checkInvalidOptions = (options) => {
    // options should be an object and should be not null
    if (typeof options !== "object" || options === null) {
      console.warn("options must be an object");
      return true;
    }

    // options.baseUrl should be a string
    if (options.baseUrl && typeof options.baseUrl !== "string") {
      console.warn("baseUrl must be a string");
      return true;
    }

    // options.createPaymentSession is required
    if (!options.createPaymentSession) {
      console.warn("createPaymentSession is required");
      return true;
    }

    // options.createPaymentSession should be a function type
    if (typeof options.createPaymentSession !== "function") {
      console.warn("createPaymentSession must be a function");
      return true;
    }

    // options.failurePaymentCallback is provided it should be a function
    if (
      options.failurePaymentCallback &&
      typeof options.failurePaymentCallback !== "function"
    ) {
      console.warn("failurePaymentCallback must be a function");
      return true;
    }

    // options.successPaymentCallback is provided it should be a function
    if (
      options.successPaymentCallback &&
      typeof options.successPaymentCallback !== "function"
    ) {
      console.warn("successPaymentCallback must be a function");
      return true;
    }

    // if options.genericErrorCallback is provided it should be a function
    if (
      options.genericErrorCallback &&
      typeof options.genericErrorCallback !== "function"
    ) {
      console.warn("genericErrorCallback must be a function");
      return true;
    }

    // if options.genericErrorCallback is provided it should be a function
    if (options.onModalClose && typeof options.onModalClose !== "function") {
      console.warn("onModalClose must be a function");
      return true;
    }

    return false;
  };

  export const formatAmountToDisplay = (amount, currency, isStreamableCurr) => {
    if (!(amount && currency)) return "---";

    const formattedAmount = `${amount} ${currency}`;

    return isStreamableCurr ? `${formattedAmount}/sec` : formattedAmount;
  };

  export const setError = (error, walletAddress = "") => {
    // setting it to null because if the same error is set twice, the second time it doesn't trigger the subscribers
    paymentErrorsStore.set(null);

    switch (error.message) {
      case "Authorization__AuthorizedFlowAlreadyExists":
        paymentErrorsStore.set("Payment authorization already exist");
        break;
      case "ACTION_REJECTED":
        paymentErrorsStore.set("Payment authorization rejected");
        break;
      case "CALL_EXCEPTION":
        paymentErrorsStore.set("Transaction failed");
        break;
      case "UNSUPPORTED_OPERATION":
        paymentErrorsStore.set("Operation not supported");
        break;
      case "INSUFFICIENT_FUNDS":
        paymentErrorsStore.set(
          "Insufficient funds to complete the transaction (not enough funds to cover fee)",
        );
        break;
      case "INSUFFICIENT_BALANCE_FOR_STREAM":
        paymentErrorsStore.set(
          `Your balance is below the minimum required to start the stream on account ${walletAddress}`,
        );
        break;
      case "NO_PAYMENT_SESSION_ID":
        paymentErrorsStore.set("Missing Payment Session Id");
        break;
      case "MISSING_STREAM_MANAGER_ADDRESS":
        paymentErrorsStore.set(
          "There was an error with the contract configuration",
        );
        break;
      case "PAYMENT_INTENT_NOT_FOUND":
        paymentErrorsStore.set(
          "Order id not found on our systems. Please contact the support.",
        );
        break;
      case "Unexpected_Error":
        paymentErrorsStore.set("Unexpected error during the payment");
        break;
      case "User closed the modal":
        paymentErrorsStore.set("Payment has been cancelled");
        break;
      case "Invalid transaction hash":
        paymentErrorsStore.set(
          "The transaction hash is in an invalid format, please contact the support.",
        );
        break;
      case "UNSUPPORTED_AUTH_ACTION":
        paymentErrorsStore.set(
          "Unsupported authorization action. Please try again.",
        );
        break;
      default:
        paymentErrorsStore.set("Unexpected error. Please try again.");
        break;
    }
  };

  export const shortenString = (str, startChars, endChars) => {
    if (str.length <= startChars + endChars) {
      return str;
    }
    return (
      str.substring(0, startChars) +
      "..." +
      str.substring(str.length - endChars)
    );
  };

  export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).catch((err) => {
      setError(new Error(`Error copying text: ${err}`));
    });
  };

  export const formatNetworkName = (network) => {
    switch (network) {
      case "BINANCE_TEST_NET":
        return "BSC Testnet";
      case "BINANCE_MAIN_NET":
        return "BSC Mainnet";
      case "GNOSIS_MAIN_NET":
        return "Gnosis";
    }
  };

  export const getProviderNameFromPaymentType = (paymentType) => {
    switch (paymentType) {
      case "card":
        return "STRIPE";
      case "fixed":
      case "stream":
        return "CRYPTO";
      default:
        throw new Error(`Unsupported payment type: ${paymentType}`);
    }
  };
</script>
